import React from 'react';

// components
import Layout from '../components/layout';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered, faCogs, faTasks } from '@fortawesome/free-solid-svg-icons';

const WebsitesPage = () => {
  return(
    <Layout>
      <div className="container__box">
        <h1><span className="heading__naples">Strony</span> internetowe</h1>
        <p>Tworzymy zoptymalizowane, czytelne oraz zgodne z <a href="https://www.w3.org/TR/WCAG20/" target="_blank" rel="noreferrer" aria-label="Web Content Accessibility Guidelines">WCAG 2.0</a> witryny internetowe.</p>
      </div>

      <div className="container__graybox">
        <h2>Wszystko w naszych rękach <FontAwesomeIcon icon={faTasks} className="ml-1" /></h2>
        <p>Skonfigurujemy wszystko za Ciebie, usługa stworzenia witryny internetowej obejmuje również jej publikację.</p>
      </div>

      <div className="container__box">
        <h2>Konfiguracja <FontAwesomeIcon icon={faCogs} className="ml-1"/></h2>
        <p>Konfigurujemy domenę oraz hosting dla tworzonej witryny internetowej.</p>
      </div>

      <div className="container__graybox">
        <h2>Bądź wyżej niż inni <FontAwesomeIcon icon={faFlagCheckered} className="ml-1" /></h2>
        <p>Pozycjonujemy strony zgodnie z standardami SEO.</p>
      </div>
    </Layout>
  );
}

export default WebsitesPage;